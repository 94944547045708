
import { Controller } from "stimulus"

export default class extends Controller {

  static values = { target: String, toggle: { type: Boolean, default: false} }

  connect() {
    // console.log('boostrap-collapse-toggler connected', this.element)

    if(this.toggleValue){
      this.toggle()
    }
  }


  toggle() {
    var tog   = document.getElementById(this.targetValue);
    var cntrl = new bootstrap.Collapse(tog);
    cntrl.toggle();
  }
}


