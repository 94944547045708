import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "input", 'icon' ]

  connect() {
    console.log('reveal secret connected', this.element, this.inputTarget, this.iconTarget)
  }

  toggle( event ) {
    $(this.inputTarget).focus()

    if ( $(this.inputTarget).prop('type') == 'password' ) {
      $(this.inputTarget).prop('type', 'text')
      $(this.iconTarget).removeClass('bi-eye')
      $(this.iconTarget).addClass('bi-eye-slash')
    } else {
      $(this.inputTarget).prop('type', 'password')
      $(this.iconTarget).removeClass('bi-eye-slash')
      $(this.iconTarget).addClass('bi-eye')  
    }
  }
}

