// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// Rails.start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo


import * as ActiveStorage from "@rails/activestorage"
import "channels"

ActiveStorage.start()

import("./activestorage_direct_upload.js")


// Stimulus
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


// ActionText
require("trix")
require("@rails/actiontext")

import "./trix-editor-overrides"
