import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab" ]

  connect() {
    // console.log('frame loader connected', this.element)
  }

  loadFrame( event ) {
    event.preventDefault()

    var url    = $(event.target).data('url')
    var target = $(event.target).data('frame')
    
    $(target).attr('src', url)
  }



  directLoadFrame(event) {
    event.preventDefault()

    var url    = $(event.target).data('url')
    var target = $(event.target).data('frame')
    var tab_id = $(event.target).data('tab')

   if(tab_id){
      var someTabTriggerEl = document.querySelector(tab_id)
      var tab = new bootstrap.Tab(someTabTriggerEl)
      tab.show()
    }

    $(target).attr('src', url)
  }
}

