import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "source" ]

  connect() {
    // console.log('clipboard connected', this.element, this.sourceTarget)
  }

  copy( event ) {
    var text = $(this.sourceTarget).data('clipboard')
 
     if (text !== undefined) {
      navigator.clipboard.writeText(text)
      $(this.sourceTarget).removeClass('text-dark').addClass('text-success')
    }
  }
}

