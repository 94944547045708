import { Controller } from "stimulus"

export default class extends Controller {

  _maxCount = 300

  connect() {
    // console.log('character counter connected', this.element)

    var count   = $(this.element).val().length
    var counter = $(this.element).next()

    this.update_element(counter, count)
  }




  update_count(event) {
    var count   = $(event.target).val().length
    var counter = $(event.target).next()

    this.update_element(counter, count)
  }



  update_element(ele, count){
    if(count > this._maxCount) {
      ele.addClass('over-character-count')
      ele.removeClass('under-character-count')
    } else {
      ele.addClass('under-character-count')
      ele.removeClass('over-character-count')
    }

    ele.html(count + "/" + this._maxCount + " characters")
  }
}

