
import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    console.log('video container connected', this.element)
  }



  reveal(event) {
    event.preventDefault()

    var vFrame           = $('#video-container > iframe')
    var container        = $('#video-container')
    var video_embed_url  = container.data('video-embed') 
    
    vFrame.prop('src', video_embed_url)
    container.removeClass('hidden')
  }
}


