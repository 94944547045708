
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // console.log('device code connected', this.element)

    // use an indexed DB if the browser supports it
    if (window.indexedDB) {
      this.set_code_from_db()

    } else if (typeof(Storage) != "undefined"){

    }
  }



  set_code_from_storage() {
    var dcode = window.localStorage.getItem("dcode");

    if (!dcode) {
      this.update_dcode()
    } else {
      this.update_dcode(dcode)
    }

  }



  set_code_from_db() {
    var current_context = this
    var open_request    = window.indexedDB.open("FilmBöd", 1);

    open_request.onupgradeneeded = function(event) {
      // Save the IDBDatabase interface
      var db = event.target.result;

      // Create an objectStore for this database
      var objectStore = db.createObjectStore("settings", { keyPath: "key" });
    };

    open_request.onerror = function(event) {
     console.log("IndexDB error: " + request.errorCode);
    };

    open_request.onsuccess = function(event) {
      var db = event.target.result;

      db.onerror = function(event) {
        console.error("Database error: " + event.target.errorCode);
      };


      var transaction  = db.transaction(["settings"], "readwrite");
      var objectStore  = transaction.objectStore("settings");
      var read_request = objectStore.get("dcode");      

      read_request.onerror = function(event) {
        console.log('Error reading from database')
      };

      read_request.onsuccess = function(event, context = current_context) {
        if (read_request.result === undefined) {
          var new_code      = context.update_dcode()
          // console.log('new code: ' + new_code)

          var data          = {key: 'dcode', value: new_code}
          var write_request = objectStore.put(data);

          write_request.onerror = function(event) {
            console.log('Error writing to Database')
          };

        } else {
          context.update_dcode(read_request.result.value)
        }
      };
    };
  }



  update_dcode(code) {
    var dcode = ''

    if (code === undefined) {
      var result     = '';
      var chars      = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charLength = chars.length;

      for ( var i = 0; i < 10; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
      }
      dcode = result;

    } else {
      dcode = code
    }

    $('#forms_public_session_device_code').val(dcode)

    return dcode
  }
}


