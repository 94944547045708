import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // console.log('table select connected', this.element)
  }

  rowSelected( event ) {
    if (event.target.nodeName == 'A')
      return;

    if (event.target.nodeName == 'INPUT')
      return;


    var frame = event.currentTarget.getAttribute('data-turbo-frame')
    var url   = event.currentTarget.getAttribute('data-url')


    if (frame)
      $(frame).attr('src', url)
    else
      Turbo.visit(url)
  }
}

