
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // console.log('flash-alert connected', this.element)

    $(this.element).slideDown('slow')

  }


  dismiss() {
    $(this.element).slideUp('swing', $(this.element).remove)
  }
}


