
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "searchForm", "searchBox", "searchIcon", "cancelIcon", "submitButton", "searchButtonBox" ]

  _intervalTimer = null


  connect() {
    // console.log('admins/toolbar connected', this.element)
    if(this.searchBoxTarget.value.length == 0) {
      this._displaySearchIcon()
    } else {
     this._displayResetIcon()
    }
  }



  search(event) {
    this._clearTimer()
    event.preventDefault()

    const keyPress  = event.code
    const valLength = this.searchBoxTarget.value.length

    if(valLength == 0) {
      this._displaySearchIcon()
      $(this.submitButtonTarget).click()
    } else {
      this._displayResetIcon()

      if(keyPress == 'Enter'){
        $(this.submitButtonTarget).click()
      } else {
        this._setTimer()
      }
    }
  }


  reset() {
    this._clearTimer()
    this.searchBoxTarget.value  = ''
    this._displaySearchIcon()
    $(this.submitButtonTarget).click()
  }


  _clearTimer() {
    clearInterval(this._intervalTimer)
  }



  _setTimer() {
    this._intervalTimer = setTimeout(function(ele){$(ele).click()}, 750, this.submitButtonTarget)
  }



  _displaySearchIcon() {
    this.searchIconTarget.classList.remove('bi-x', 'cursor-pointer')
    this.searchIconTarget.classList.add('bi-search')
    this.searchButtonBoxTarget.classList.add('seethrough')
  }



  _displayResetIcon() {
    this.searchIconTarget.classList.remove('bi-search')
    this.searchIconTarget.classList.add('bi-x', 'cursor-pointer')
    this.searchButtonBoxTarget.classList.remove('seethrough')
  }

}


