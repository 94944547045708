import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab" ]

  connect() {
    // console.log('tab select connected', this.element)

    if($(this.element).hasClass('active')){
      this.loadContent()
    }
  }

  loadContent( event ) {
    var cache_buster = Math.floor(new Date().getTime() / 1000)
    var url          = $(this.tabTarget).data('url') + '?cb=' + cache_buster
    var target       = $(this.tabTarget).data('frame')

    $(target).attr('src', url)
    this._loaded     = true
  }
}

