import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // console.log('page topper connected', this.element)
    window.scrollTo(0, 0); 
  }
}

