import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // console.log('boolean display connected', this.element)
  }

  toggle( event ) {
    var target = $(event.target).parents('.form-group').data('display-target')

    if($(event.target).val() == 1){
      $(target).removeClass('hidden')
    } else {
      $(target).addClass('hidden')
    }
  }
}

