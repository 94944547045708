import { Controller } from "stimulus"


export default class extends Controller {

  connect() {
    // console.log('timeout connected', this.element)

    if(document.querySelector('body').hasAttribute("data-timeout-url")){
      Controller.timeout_url   = document.querySelector('body').dataset.timeoutUrl
    }


    if(document.querySelector('body').hasAttribute("data-timeout-delay")){
      Controller.timeout_delay = Math.floor(document.querySelector('body').dataset.timeoutDelay)
    }

    this.reset()
  }



  reset() {
    // console.log('reset requested')
    if(Controller.timeout_url == null){
      // console.log("url null")
      return
    }

    if(Controller.timeout_delay == null){
      // console.log("delay null")
      return
    }

    var timestamp              = performance.now()
    var time_from_last_request = Math.floor(timestamp - Controller.timeout_reset_at)

    if(time_from_last_request > 3000){
      // console.log('resetting', Controller.timeout_url, Controller.timeout_delay)
      Controller.timeout_reset_at = timestamp

      clearInterval(Controller.timeout_interval_timer)

      var timeout_url = Controller.timeout_url
      
      Controller.timeout_interval_timer =  setTimeout(function() {
        var cache_buster  = Math.floor(new Date().getTime() / 1000)
        var url           = timeout_url + '?cb=' + cache_buster

        Turbo.visit(url)
      }, Controller.timeout_delay )
      // console.log('timeout reset')
    }
  }
}

// Global vars
Controller.timeout_interval_timer = null
Controller.timeout_url            = null
Controller.timeout_delay          = null
Controller.timeout_reset_at       = 1
