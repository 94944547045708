
import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    // console.log('background connected', this.element)

    var imageUrl   = $(this.element).data('image-url');
    var mutedImage = $(this.element).data('muted-image');    
    var css = {}

    if(mutedImage === undefined){
      css['background'] = "url(\"" + imageUrl + "\")";
    } else {
      css['background'] = "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(\"" + imageUrl + "\")";
    }

    css['background-position']   = 'center center';
    css['background-repeat']     = 'no-repeat';
    css['background-blend-mode'] = 'darken';
    css['background-size']       = 'cover';


    $(this.element).css(css);
  }
}


