
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "password" ]

  _intervalTimer = null


  connect() {
    // console.log('password_meter connected', this.element)
  }



  update(event) {
    this._clearTimer()
    event.preventDefault()
    this._setTimer()
  }


  reset() {
    this._clearTimer()
    this.searchBoxTarget.value  = ''
    this._displaySearchIcon()
    $(this.submitButtonTarget).click()
  }


  _clearTimer() {
    clearInterval(this._intervalTimer)
  }



  _setTimer() {
    console.log('password_meter target', this.passwordTarget.value)
    const encoded = encodeURI(this.element.value)
    const url = '/password_meter?password=' + encoded
    this._intervalTimer = setTimeout(function(ele){$('#password_meter').attr('src', url)}, 250)
  }
}


