
import { Controller } from "stimulus"

export default class extends Controller {

  _toast = null

  connect() {
    // console.log('flash-toast connected', this.element)

    // reveal the toast
    this._toast = new bootstrap.Toast(this.element, {animation: true, autohide: true})
    this._toast.show()

  }

  dismiss() {
    this._toast.hide()
  }
}


