
import { Controller } from "stimulus"

export default class extends Controller {

  _toast = null

  connect() {
    // console.log('redirection connected', this.element)

    Turbo.visit($(this.element).data('redirect-url'))
  }
}


