
import { Controller } from "stimulus"

export default class extends Controller {

  _intervalTimer = null


  connect() {
    // console.log('email preview connected', this.element)
  }



  preview(event) {
    this._clearTimer()
    event.preventDefault()
    this._setTimer()
  }


  _clearTimer() {
    clearInterval(this._intervalTimer)
  }



  _setTimer() {
    // this._intervalTimer = setTimeout(function(ele){$(ele).click()}, 750, this.submitButtonTarget)
    this._intervalTimer = setTimeout(this._updatePreview, 750)
  }



  _updatePreview() {
    var format  = $("#email_template_draft_format option:selected").val()
    var content = $('#email_template_draft_content').val()

    var url = new URL($("#preview_form").data('preview-url'))
    url.searchParams.append("preview_format",  format)
    url.searchParams.append("preview_content", content)
    
    $("#preview_frame").attr('src', url.href)
  }
}


