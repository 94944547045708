
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('bob connected', this.element)
  }


  show(event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    alert('bob#show called')
  }
}


