import { Controller } from 'stimulus';

// Turbo expects frames to redirect after post and dosent let you navigate
// from a frame more than once, to fix this you have to reset the frame
// src and all works. this is a monkey patch. to use it
// place it directly on a frame, data-controller="frame-reset"
// data-action="turbo:submit-end->reset-frame-src#reset"
//
// https://github.com/hotwired/turbo/issues/249

export default class extends Controller {
  connect() {
     // console.log('frame reset connected', this.element)
  }

  reset() {
    this.element.src = "";
    window.scrollTo(0,0)
  }
}


// turbo_frame_tag some-id,
                    // data: {controller: 'frame-reset',
                           // action: 'turbo:submit-end->frame-reset#reset'} 

