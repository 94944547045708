
import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    console.log('video modal connected', this.element)
  }



  show(event) {
    event.preventDefault()

    var tFrame   = $('#video_modal_content')
    var video_id = $(event.target).closest("a").data('video-id')

    tFrame.prop('src', '/videos/' + video_id)

    var myModal = new bootstrap.Modal(document.getElementById("video_modal"), {});
    myModal.show()
  }
}


