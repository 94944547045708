import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tab" ]

  connect() {
    // console.log('frame loader connected', this.element)

    var cache_buster = Math.floor(new Date().getTime() / 1000)
    const url        = $(this.element).data('url') + '?cb=' + cache_buster
    const target     = $(this.element).data('frame')
    const delay      = $(this.element).data('delay')


    setTimeout(function() {
      $(target).attr('src', url)
    }, delay )
  }
}

